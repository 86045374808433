/* Custom Scrollbar - START */
/* width */
::-webkit-scrollbar {
	width: 10px;
	height: 10px;
}
/* Track */
::-webkit-scrollbar-track {
	background: transparent;
}
/* Handle */
::-webkit-scrollbar-thumb {
	background: #999;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #666;
}
/* Custom Scrollbar - END */

* {
    font-family: 'Comfortaa', 'Noto Sans TC';
}

body {
    margin: 0; /* as body has default margin */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}