@import "basic.scss";

h1 {
    margin: 0; // fix margin-bottom: 8 issue
}

.App {
    margin: auto;
    // min-width: 750px;
}

.header {
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.5;

    .icon {
        font-size: 2.1rem;
    }
}

.solid {
    transition: background-color 1s ease 0s;
    box-shadow: 0 0 10px;
}

.home-section-1 {
    background: url("../images/bookcase-chairs-clean_1920.jpg") no-repeat center;
    // background-attachment: fixed;
    background-size: cover;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.simple-slogan {
    color: white;
    font-weight: 600;
    height: 60vh;
    display: flex;
    flex-flow: row wrap;
    align-content: center;

    h1 {
        margin: auto;
    }
}

@mixin home-section-img {
    max-width: 100%;
    max-height: 100%;
    padding: 10px 0;
}

.home-section-image-left {
    padding: 50px 0;
    background-color: #d9d9d9;
    background-image: linear-gradient(315deg, #d9d9d9 0%, #f6f2f2 74%);

    img {
        @include home-section-img;
    }
}

.home-section-image-right {
    padding: 50px 0;
    background-color: #f1f2f6;
    background-image: linear-gradient(315deg, #f1f2f6 0%, #c9c6c6 74%);

    img {
        @include home-section-img;
    }
}

.home-feature {
    padding: 50px 0;
    background: #f5f5f5;
}

.home-section-8 {
    padding: 50px 0;
    background-color: #f1f2f6;
    background-image: linear-gradient(315deg, #f1f2f6 0%, #c9c6c6 74%);

    img {
        @include home-section-img;
    }
}

.how-it-work {
    padding: 50px 0;
    background: #f5f5f5;

    img {
        @include home-section-img;
    }
}

.faq {
    padding: 15px 5vw;
    overflow: auto;
    background: #f2f2f2dd;

    img {
        max-width: 100%;
        max-height: 100%;
    }
}

.privacy {
    padding: 0px 5vw;
    overflow: auto;
    background: #f2f2f2dd;  

    img {
        max-width: 100%;
        max-height: 100%;
    }
}

.under-construction {
    background-color: #f5f5f5;
}

.page-not-found {
    background-color: #f5f5f5;
}

.product-summary {
    background-color: #f5f5f5;

    .card {
        max-width: 32rem;
    }
    img {
        max-width: 100%;
        max-height: 100%;
        padding: 3px;
    }
}

.product-detail {
    img {
        @include home-section-img;
    }
}

.footer {
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 0 30px;
    // background-color: #26272b;
    // color: rgb(195, 195, 195);

    .copyright {
        // margin-right: auto;
        margin-top: auto;
        margin-bottom: auto;
    }
}
